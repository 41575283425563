import React from 'react'
import {PageProps, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const LessonMethods = (props: PageProps) => (
  <Layout>
    <SEO title="Method and lesson methods" />
    <h5>Werkwijze en lesmethodes</h5>
    <p>
      Voor het taalonderwijs gebruikt de Koningin Máxima School lesmethodes die ook op Nederlandse dagscholen gebruikt worden, aangevuld met actueel
      lesmateriaal voor het cultuuronderwijs. Daarnaast wordt minimaal twee keer per jaar een schoolbreed project gedaan, zoals de herdenking van de
      Watersnoodramp van 1953 in het kader van "De Lage Landen Waterlanden", de jaarlijkse Kinderboekenweek, het WK IJshockey Nederland - België in
      maart 2023 in Sofia, Als er toch geen oorlog was ..., Sinterklaas en nog heel veel meer.
    </p>
    <h5>Peuters</h5>
    <p>
      In deze groep ligt de nadruk op de ontwikkeling van de actieve en passieve mondelinge woordenschat door samen te spelen, praten en zingen. Dit
      gebeurt aan de hand van thema’s die passen bij de belevingswereld van de kinderen. Daarbij komen als vanzelf sociale omgangsvormen,
      zelfredzaamheid en expressie aan de orde. Tijdens de lessen wordt er veel voorgelezen, gezongen, gespeeld en geknutseld, allemaal in het
      Nederlands. De activiteiten worden onder andere gepland aan de hand van “Uk en Puk”.
    </p>
    <h5>Groep 1 en 2</h5>
    <p>
      In groep 1 en 2 ligt de nadruk op activiteiten gericht op beginnende geletterdheid, mondelinge taalontwikkeling, leesplezier en uitbreiding van
      de woordenschat. Daarnaast wordt in groep 2 spelenderwijs een start gemaakt met letters leren en woordbegrip, zodat leerlingen voorbereid worden
      op groep 3. Er wordt gewerkt met de methode Schatkist. Deze methode gaat uit van ankers (thema’s) die in de tijd kunnen worden gepositioneerd.
      Bij elk jaargetijde zijn vier ankers ontwikkeld. Binnen een anker wordt gewerkt met een ankerverhaal en diverse activiteiten die aansluiten bij
      het verhaal.
    </p>
    <h5>Primair Onderwijs (PO) - groep 3 t/m 8</h5>
    <p>
      In groep 3 ligt het zwaartepunt van de lessen op het vloeiend leren lezen, schrijven en spellen. Hiervoor wordt gebruikgemaakt van de lesmethode
      ‘Veilig leren lezen’ aangevuld met de methodiek en het materiaal van ‘Zo Leer je Kinderen Lezen en Spellen’. Eveneens is er veel aandacht voor
      verhaalbegrip, de leesbeleving en het uitbreiden van de woordenschat. Daarnaast wordt in de lessen aandacht besteed aan spel, creatieve
      verwerking en cultuur.
    </p>
    <p>
      In groep 4 t/m 8 wordt gewerkt met de methodes ‘Taal op maat’ en ‘Spelling op maat’. In ‘Taal op maat’ worden de onderdelen spreken en
      luisteren, woordenschat, taalbeschouwing en stellen aangeboden aan de hand van een thema. De keuze voor ‘Taal op Maat’ en ‘Spelling op Maat’
      zorgt voor een continuïteit van ons aanbod en biedt een doorlopende leerlijn van groep 4 tot en met groep 8. Daarnaast wordt er vanaf groep 5
      aan de hand van de actualiteit door middel van ‘Nieuwsbegrip’ (op niveau) aandacht besteed aan begrijpend lezen, kijken, luisteren en spreken.
      Vanaf de middenbouw van het primair onderwijs, helpen we leerlingen ook met het ontwikkelen van hun digitale vaardigheden. Denk hierbij aan
      zelfstandig navigeren in Classroom, het sturen van een korte e-mail over een onderwerp, het (met hulp van ouders) zoeken naar informatie op
      internet, online taalopdrachten of het doen van interactieve games. Ook 21e eeuwse vaardigheden, zoals 'critical thinking', multimediaal
      presenteren, mediawijsheid, samenwerken, sociale en culturele vaardigheden, (wereld)burgerschap spelen zijn op allerlei manieren in ons
      onderwijs verweven.
    </p>

    <h5>Voortgezet Onderwijs</h5>
    <p>
      In het VO- onderwĳs wordt gewerkt met VO Stercollecties. Deze methode zet op een vernieuwende manier in op maatwerk in spelling en grammatica,
      literatuur en de actualiteit. De leerlingen werken toe naar een Certificaat Nederlands als Vreemde Taal (CNaVT) op minimaal B2- niveau.
    </p>
    <p>
      Verder vormt cultuuronderwĳs een belangrĳk onderdeel van de lessen. Hiervoor gebruiken we onze lesmethode, aangevuld met Nieuwsbegrip voor
      begrijpend lezen. Met de VO- leerlingen besteden we ook nadrukkelĳk aandacht aan de culturele diversiteit: de verschillen tussen
      Nederland/België en Bulgarĳe. Dit doen we niet alleen om recht te doen aan de culturele achtergrond van onze leerlingen, maar ook om hen goed
      voor te bereiden op een eventuele studie, carrière of anderszins overstap naar Nederland/België of andere plekken in de wereld.
    </p>
  </Layout>
)

export default LessonMethods
